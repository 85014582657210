<template>
  <div>
    <div class="d-flex justify-content-start align-items-center mb-3">
      <span class="title">
        <h3 style="font-size: 24px">{{ $t('account.editAccount') }}</h3>
      </span>
    </div>
    <!-- form edit  -->
    <div class="card">
      <div class="section-card">
        <div class="col-10 m-auto pt-5" v-if="!!account.detail_user">
          <a-form :form="form" @submit.prevent="submit" :layout="formLayout">
            <div class="row justify-content-between m-0 p-0">
              <div class="col-12 col-sm-6">
                <a-form-item class="w-100" :label-col="formItemLayout?.labelCol"
                  :wrapper-col="formItemLayout?.wrapperCol">
                  <label :class="$style.labelInput">{{ $t('account.storeName') }}</label>
                  <a-input v-decorator="[
                    'store_name',
                    {
                      rules: [
                        {
                          required: true,
                          message: $t('account.requiredStoreName'),
                        },
                      ],
                      initialValue: user ? account?.detail_user?.store?.name : '',
                    },
                  ]" :placeholder="$t('account.storeName')" :maxLength="30" :minLength="2"
                    class="profile-input-field" />
                </a-form-item>
              </div>
              <div class="col-12 col-sm-6"></div>
            </div>
            <div class="row justify-content-between m-0 p-0">
              <div class="col-12 col-sm-6">
                <a-form-item :label-col="formItemLayout?.labelCol" :wrapper-col="formItemLayout?.wrapperCol">
                  <label :class="$style.labelInput">{{ $t('account.firstName') }}</label>
                  <a-input v-decorator="[
                    'first_name',
                    {
                      rules: [
                        {
                          required: true,
                          message: $t('account.requiredFirstName'),
                        },
                      ],
                      initialValue: account
                        ? account?.detail_user?.user?.first_name
                        : '',
                    },
                  ]" :placeholder="$t('account.firstName')" :maxLength="25" :minLength="2"
                    class="profile-input-field" />
                </a-form-item>
              </div>
              <div class="col-12 col-sm-6">
                <a-form-item :label-col="formItemLayout?.labelCol" :wrapper-col="formItemLayout?.wrapperCol">
                  <label :class="$style.labelInput">{{ $t('account.lastName') }}</label>
                  <a-input v-decorator="[
                    'last_name',
                    {
                      rules: [
                        {
                          required: false,
                          message: $t('account.requiredLastName'),
                        },
                      ],
                      initialValue: account
                        ? account?.detail_user?.user?.last_name
                        : '',
                    },
                  ]" :placeholder="$t('account.lastName')" :maxLength="25" :minLength="1"
                    class="profile-input-field" />
                </a-form-item>
              </div>
            </div>

            <div class="row justify-content-between m-0 p-0">
              <div class="col-12 col-sm-6" :style="wrapStyles">
                <a-form-item :label-col="formItemLayout?.labelCol" :wrapper-col="formItemLayout?.wrapperCol">
                  <a-tooltip :title="$t('account.howToChangeEmail')" :get-popup-container="getPopupContainer"
                    placement="bottomLeft">
                    <div class="w-100" style="display: inline-block;">
                      <label :class="$style.labelInput">{{ $t('form.email') }}</label>
                      <a-input :disabled="disabled" v-decorator="[
                        'email',
                        {
                          rules: [
                            {
                              required: false,
                              message: $t('account.requiredEmail'),
                            },
                          ],
                          initialValue: account
                            ? account?.detail_user?.user?.email
                            : '',
                        },
                      ]" :placeholder="$t('form.email')" type="email" style="pointer-events: none;"
                        class="profile-input-field" />
                    </div>
                  </a-tooltip>
                </a-form-item>
              </div>

              <div class="col-12 col-sm-6" :style="wrapStyles">
                <a-form-item :label-col="formItemLayout?.labelCol" :wrapper-col="formItemLayout?.wrapperCol">
                  <a-tooltip :title="$t('account.howToChangePhone')" :get-popup-container="getPopupContainer"
                    placement="bottomLeft">
                    <div class="w-100" style="display: inline-block;">
                      <label :class="$style.labelInput">{{ $t('account.telephone') }}</label>
                      <a-input :disabled="disabled" v-decorator="[
                        'phone',
                        {
                          rules: [
                            {
                              required: false,
                              message: $t('account.requiredPhone'),
                            },
                          ],
                          initialValue: account
                            ? account?.detail_user?.user?.phone
                            : '',
                        },
                      ]" :placeholder="$t('account.telephone')" type="number" maxlength="12"
                        style="pointer-events: none;" class="profile-input-field" />
                    </div>
                  </a-tooltip>
                </a-form-item>
              </div>
            </div>
            <h5 class="mx-3 my-4">{{ $t('account.address') }}</h5>
            <div class="row justify-content-between m-0 p-0">
              <div class="col-12 col-sm-6">
                <a-form-item :label-col="formItemLayout?.labelCol" :wrapper-col="formItemLayout?.wrapperCol">
                  <label :class="$style.labelInput">{{ $t('account.streetName') }}</label>
                  <a-input v-decorator="[
                    'address_line1',
                    {
                      rules: [
                        {
                          required: true,
                          message: $t('account.requiredAddress'),
                        },
                      ],
                      initialValue: account
                        ? account?.detail_user?.user?.address?.line1
                        : '',
                    },
                  ]" :placeholder="$t('account.streetName')" class="profile-input-field" />
                </a-form-item>
              </div>
              <div class="col-12 col-sm-6" v-if="account?.detail_user?.user?.address">
                <a-form-item :label-col="formItemLayout?.labelCol" :wrapper-col="formItemLayout?.wrapperCol">
                  <label :class="$style.labelInput">{{ $t('account.rtrw') }}</label>
                  <a-input v-decorator="[
                    'address_line2',
                    {
                      rules: [
                        {
                          required: true,
                          message: $t('account.requiredAddress'),
                        },
                      ],
                      initialValue: account
                        ? account?.detail_user?.user?.address?.line2
                        : '',
                    },
                  ]" :placeholder="$t('account.rtrw')" class="profile-input-field" />
                </a-form-item>
              </div>
            </div>
            <div class="row justify-content-between m-0 p-0">
              <div class="col-12 col-sm-6">
                <a-col :xs="24" :sm="24" :md="24">
                  <a-form-item>
                    <label :class="$style.labelInput">{{ $t('form.country') }}</label>
                    <a-select show-search v-decorator="[
                      'country',
                      {
                        rules: [
                          {
                            required: true,
                          },
                        ],
                        initialValue: initialValueCountry && initialValueCountry,
                      },
                    ]" :filter-option="filterOption" option-filter-prop="children"
                      :placeholder="$t('authForm.country')" @change="handleSelectCountry" class="profile-input-field">
                      <a-select-option v-for="(country, index) in countryList" :key="index" :value="country.value">
                        {{ country.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </div>
              <div class="col-12 col-sm-6">
                <a-col :xs="24" :sm="24" :md="24">
                  <a-form-item>
                    <label :class="$style.labelInput">{{ $t('form.city') }}</label>
                    <a-select show-search v-decorator="[
                      'city',
                      {
                        rules: [
                          {
                            required: true,
                          },
                        ],
                        initialValue: account
                          ? account?.detail_user?.address?.city_id.toString()
                          : '',
                      },
                    ]" :filter-option="filterOption" option-filter-prop="children"
                      :placeholder="$t('form.placeholderCity')" @change="handleSelectCity" class="profile-input-field">
                      <a-select-option v-for="(city, index) in cityList" :key="index" :value="city.value">
                        {{ city.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </div>
            </div>
            <div class="row justify-content-between m-0 p-0">
              <div class="col-12 col-sm-6">
                <a-col :xs="24" :sm="24" :md="24">
                  <a-form-item>
                    <label :class="$style.labelInput">{{ $t('form.area') }}</label>
                    <a-select show-search v-decorator="[
                      'area',
                      {
                        rules: [
                          {
                            required: true,
                          },
                        ],
                        initialValue: account
                          ? account?.detail_user?.address?.area_id.toString()
                          : '',
                      },
                    ]" :filter-option="filterOption" option-filter-prop="children"
                      :placeholder="$t('form.placeholderArea')" @change="handleSelectArea" class="profile-input-field">
                      <a-select-option v-for="(area, index) in areaList" :value="area.value" :key="index">
                        {{ area.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </div>
              <div class="col-12 col-sm-6">
                <a-col :xs="24" :sm="24" :md="24">
                  <a-form-item>
                    <label :class="$style.labelInput">{{ $t('form.postal_code') }}</label>
                    <a-input v-decorator="[
                      'code_pos',
                      {
                        rules: [
                          {
                            required: true,
                          },
                        ],
                        initialValue: account
                          ? account?.detail_user?.address?.postcode
                          : '',
                      },
                    ]" :placeholder="$t('form.postal_code')" class="profile-input-field" />
                  </a-form-item>
                </a-col>
              </div>
            </div>
            <!-- ubah data alamat  -->
            <!-- button  -->
            <div class="text-right mr-3">
              <a-form-item :wrapper-col="buttonItemLayout?.wrapperCol">
                <a-button class="btn-submit" type="primary" size="large" :loading="loading" @click="submit">
                  {{ $t('account.submit') }}
                </a-button>
              </a-form-item>
            </div>
          </a-form>
        </div>
      </div>
    </div>
    <b-modal :no-close-on-backdrop="true" hide-footer hide-header centered v-model="showModalForceLogout" size="md">
      <div class="d-flex flex-column align-items-center text-center">
        <h5 class="font-weight-bold mb-2">{{ $t('account.successEditProfileModalTitle') }}</h5>
        <p class="mb-2 py-3">{{ $t('account.successEditProfileModalBody') }}</p>
        <a-button
          type="primary"
          size="large"
          class="btn-submit text-center w-fit mt-2"
          @click="onRedirect"
        >
          {{ $t('account.successEditProfileModalButton') }}
        </a-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import { getImagePreview } from '@/services/image/utils'

export default {
  name: 'Edit Profile',
  components: {
  },
  data: function () {
    return {
      form: this.$form.createForm(this),
      loading: false,
      countryList: [],
      cityList: [],
      areaList: [],
      isInitialValue: true,
      initialValueCountry: null,
      disabled: true,
      mapDetail: '',
      latitude: '0',
      longitude: '0',
      showModalForceLogout: false,
    }
  },
  computed: {
    ...mapState(['user'], {
      user_data: (state) => state.user.user_data,
    }),
    ...mapState(['account'], {
      detail_user: (state) => state.detail_user,
      profile: (state) => state.profile,
    }),
    formItemLayout() {
      const { formLayout } = this
      return formLayout === 'horizontal'
        ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 },
        }
        : {}
    },
    buttonItemLayout() {
      const { formLayout } = this
      return formLayout === 'horizontal'
        ? {
          wrapperCol: { span: 14, offset: 4 },
        }
        : {}
    },
  },
  mounted() {
    this.fetchDetailUser()
    this.fetchRegionCountry()
    setTimeout(() => {
      this.fetchAreaList(this.account?.detail_user?.address?.country_id, 'city')
    }, 750)
    setTimeout(() => {
      this.fetchAreaList(this.account?.detail_user?.address?.city_id, 'area')
      this.loading = false
    }, 1500)
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    fetchDetailUser() {
      this.$store
        .dispatch('account/GETDETAILUSER', {
          buyer_id: this.$store.state.user.user_data.buyer_id,
        })
        .then((data) => {
          if (data.address) {
            this.mapDetail = data.address.map_detail
            this.latitude = data.address.latitude
            this.longitude = data.address.longitude
          }
        })
        .catch((err) => {
          this.$notification.error({
            message: 'Sorry, Error get data user detail',
            placement: 'bottomRight',
            description: err.response?.data?.message || err.message,
          })
        })
    },
    fetchRegionCountry() {
      this.$store
        .dispatch('data/GETREGIONCOUNTRY', this.$store.state.app.vendor_hashid)
        .then((data) => {
          this.countryList = data.list
          const getIndonesia = data.list.find(
            (item) => item.label.toLowerCase() === 'indonesia')

          if (getIndonesia) {
            this.handleSelectCountry(getIndonesia.value)
            this.initialValueCountry = getIndonesia.value
          }
        })
    },
    fetchAreaList(parentId, param) {
      this.$store
        .dispatch('data/GETAREALLIST', {
          parentId,
        })
        .then((data) => {
          switch (param) {
            case 'city':
              this.cityList = data.list
              break

            case 'area':
              this.areaList = data.list
              break

            default:
              break
          }
        })
    },
    handleChangeCity(e) {
      const findValueCity = this.cityList.find((item) => item.value === e)
      this.$store.dispatch('data/GETCITYLIST', {
        value: findValueCity.value,
        type: 'area',
      })
      this.isNewValueCity = true
    },
    handleSelectCountry(e) {
      this.fetchAreaList(e, 'city')
    },
    handleSelectCity(e) {
      const key = this.cityList.find((item) => item.value === e)
      this.fetchAreaList(key.value, 'area')
    },
    handleSelectArea(e) {
      const findArea = this.areaList.find((item) => item.value === e)
      this.form.setFieldsValue({
        code_pos: findArea.postcode,
      })
    },
    onRedirect() {
      if (this.$store.state.app.store_url) {
        window.open(this.$store.state.app.store_url, '_self')
      } else {
        this.$router.push('/auth/login')
      }
    },
    submit() {
      this.form.validateFieldsAndScroll((errors, values) => {
        if (errors) return
        this.loading = true
        const buyer_id = this.$store.state.user.user_data.buyer_id
        const user_id = this.account?.detail_user?.user?.id
        const email = this.account?.detail_user?.user?.email
        const phone = this.account?.detail_user?.user?.phone

        this.$store
          .dispatch('account/EDITDETAILUSER', {
            buyer_id,
            values,
            user_id,
            email,
            phone,
            mapDetail: this.mapDetail,
            latitude: this.latitude,
            longitude: this.longitude,
          })
          .then((_) => {
            this.isInitialValue = true
            this.$store.dispatch('user/LOGOUTWITHOUTRELOAD').finally(() => {
              this.loading = false
              this.showModalForceLogout = true
            })
          })
          .catch((err) => {
            this.loading = false
            this.$notification.error({
              message: this.$t('account.failedUpdateData'),
              placement: 'bottomRight',
              description: err.response?.data?.message || err.message,
            })
          })
      })
    },
  },

  created() {
    this.$store.dispatch('account/GETDETAILUSER')
    this.fetchRegionCountry()
    this.fetchAreaList()
  },
}
</script>

<style lang="scss" module>
@import "@/components/Profile/style.module.scss";
</style>
<style lang="scss" scoped>
.orderDetail {
  &-icon {
    background: #fff !important;
    padding: 6px 11px;
    border-radius: 50%;
  }
}

.image-uploader {
  .ant-upload-select-picture-card {
    margin: 0 !important;
    width: 140px !important;
    height: 140px !important;

    img {
      max-width: 100%;
    }

    i {
      font-size: 32px;
      color: #999;
    }

    .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }
  }
}

.profile-input-field {
  border: 1px solid #000000 !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  color: #000000 !important;
  width: 100%;
}

.btn-submit {
  background-color: var(--biz-brand-button);
  border: none;

  &:hover {
    background-color: var(--biz-brand-button) !important;
  }

  &:focus {
    background-color: var(--biz-brand-button) !important;
  }
}
</style>
