<template>
  <edit-profile />
</template>

<script>
import EditProfile from '@/components/Profile/Edit'

export default {
  components: {
    EditProfile,
  },
}
</script>
